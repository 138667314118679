@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-custom-container { 
    .input-custom {
        width: 280px;
        font-family: 'OpenSans', sans-serif;
        font-size: 14px;

        &.vertical {
            @include inputVertical();
        }
    }

    &.horizontal {
        @include inputContainerHorizontal();
    }

    &.vertical {
        @include inputContainerVertical();
    }
}
