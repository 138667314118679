@import '../styles/_constants.scss';

.date-picker {
  .input-dropdown-arrow {
    position: relative;
    right: calc(#{$len-s} + 11px);
    top: calc(50% - 8px);
    pointer-events: none;
  }

  .tooltip-container {
    margin-left: -2px;
  }
}

@media (max-width: $bp-m) {
  .input-text-container.date-picker.horizontal {
    width: 262px;
  }
}
