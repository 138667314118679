@import '../styles/constants';

.icon {
  &.vertical-nav {
    //margin-right: $len-s;

    svg {
      vertical-align: text-bottom;
    }
  }

  &.dropdown-arrow {
    &.collapsed {
      margin-left: $len-2xs;
      margin-right: -$len-2xs;
    }

    svg {
      vertical-align: middle;
    }
  }

  &.collapse {
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: $color-b-2;
        }
      }
    }
  }

  &.header-menu {
    display: block;
    width: $len-2xl;
    height: $len-2xl;

    &:hover {
      svg {
        path {
          fill: $color-b-2;
        }
      }
    }

    svg {
      display: block;
      height: 100%;
      margin: 0 auto;
    }
  }

  &.date-nav {
    cursor: pointer;
    &:hover {
      svg {
        circle {
          stroke: $color-a-2;
        }
        path {
          fill: $color-a-2;
        }
      }
    }
  }

  &.reservation-confirmation {
    margin-right: $len-2xs;
  }

  &.delete {
    vertical-align: text-bottom;
    margin-right: $len-3xs;
  }

  &.plus {
    margin-right: $len-3xs;
  }

  &.copy {
    vertical-align: text-bottom;
    margin-right: $len-3xs;
  }
}
