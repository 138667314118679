@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.table-with-sort {
  position: relative;
  height: 100%;

  .table-container {
    overflow-y: auto;
    max-height: 100%;
    border-bottom: 1px solid $color-d-5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &.empty {
      .empty-table {
        color: $color-d-5;
        padding: $len-s $len-m $len-s;
        border-left: 1px solid $color-d-5;
        border-right: 1px solid $color-d-5;
      }
    }

    .scroll-observer {
      height: 1px;
    }

    th {
      position: sticky;
      top: 0;
    }
  }

  .table {
    width: 100%;
    max-height: 100%;
    text-align: left;
    border-spacing: 0;
    color: $color-a-4;
    font-size: $font-size-m;
    table-layout: fixed;

    .table-head {

      .table-cell {
        position: static;
        background: $color-b-6;
        color: $color-a-2;
        font-weight: $font-weight-l;
        font-size: $font-size-s;
        padding: 0 $len-s;
        height: 35px;
        line-height: 35px;
        border-right: 1px solid $color-d-5;
        border-bottom: 1px solid $color-d-5;
        border-top: 1px solid $color-d-5;
        box-sizing: border-box;
        @include ellipsis();

        &:last-child {
          border-top-right-radius: 5px;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-left: 1px solid $color-d-5;
        }

        &.align-right {
          text-align: right;
        }

        .th-content {
          display: inline-block;
          width: calc(100% - 24px);
          vertical-align:top;
          @include ellipsis();
        }

        .tooltip-container {
          line-height: 24px;
        }

        .sort {
          float: right;
          margin-left: $len-2xs;
          margin-top: $len-3xs;
          margin-bottom: -$len-3xs;

          &:hover {
            .icon {
              cursor: pointer;
              svg {
                path {
                  fill: $color-a-2;
                }
                rect {
                  stroke: $color-a-2;
                }
              }
            }
          }

          .dropdown-items-container {
            left: 0;
            right: auto;
            top: $len-m;
          }
        }
      }

      &.scrolling {
        .table-cell {
          border-bottom: 1px solid $color-a-2;
        }
      }
    }

    .table-row {
      background: $color-d-8;

      &:nth-child(odd):not(.custom-background) {
        background: $color-d-3;
      }

      &.custom-background.emphasized {
        background: $color-d-3;
      }

      &.clickable {
        cursor: pointer;
      }

      .table-cell {
        padding: 0 $len-s;
        height: 35px;
        line-height: 35px;
        border-right: 1px solid $color-d-2;
        border-bottom: 1px solid transparent;
        border-top: 1px solid transparent;
        box-sizing: border-box;

        &:last-child {
          border-right: none;
          border-right: 1px solid $color-d-5;
        }

        &:first-child {
          border-left: 1px solid $color-d-5;
        }

        &.align-right {
          text-align: right;
        }
      }

      &:last-child {
        .table-cell {
          padding-bottom: 5px;
        }
      }

      &:first-child {
        .table-cell {
          padding-top: 5px;
        }
      }

      &:hover:not(.passive) {
        .table-cell {
          border-bottom: 1px solid $color-a-2;
          border-top: 1px solid $color-a-2;
        }
      }
    }
  }

  .sort-container {
    .sort-dropdown {
      position: absolute;
      top: $len-ml;
      background-color: $color-w-1;
      z-index: 1;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

      .sort-title {
        border-bottom: 1px solid $color-d-4;
        padding: $len-xs $len-s;
        min-width: 150px;
        text-transform: uppercase;
        pointer-events: none;
      }

      .sort-option {
        padding: $len-xs $len-s;
        min-width: 150px;
        cursor: pointer;

        &:hover {
          background-color: $color-d-3;
        }

        .radio-control {
          margin-right: $len-xs;
          vertical-align: text-bottom;
        }

        .close-control {
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 14px;
          position: relative;

          &:before, &:after {
            content: ' ';
            background-color: $color-a-1;
            width: 2px;
            height: $len-s;
            position: absolute;
            left: 7px;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }

        .radio-label {
          display: inline-block;
          vertical-align: text-top;
        }
      }
    }
  }
}

.headless-table {
  .table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .table-row:first-child {
      .table-cell {
        border-top: 1px solid $color-d-5;
      }
    }

    .table-cell {
      box-sizing: border-box;
    }
  }
}

@media (max-width: $bp-m) {
  .table-with-sort {
    .table-container {
      border-radius: 0;
      border-top: 1px solid $color-d-5;
      border-bottom: 1px solid $color-d-5;

      .scroll-observer {
        height: 0;
      }
    }

    .table {
      .table-head {
        display: none;
      }

      .table-row {
        display: grid;
        padding-top: $len-2xs;
        padding-bottom: $len-2xs;

        &:not(.no-mobile-columns) {
          grid-template-columns: 50% 50%;
        }

        &:nth-child(odd):not(.custom-background) {
          background: $color-d-4;
        }

        &.custom-background.emphasized {
          background: $color-d-4;
        }

        &:first-child {
          .table-cell {
            padding-top: 0;
          }
        }

        &:last-child {
          .table-cell {
            padding-bottom: 0;
          }
        }

        &:hover {
          .table-cell {
            border: none !important;
          }
        }

        &:hover:not(.passive) {
          border-bottom: 1px solid $color-a-2;
          border-top: 1px solid $color-a-2;
        }

        .table-cell {
          display: block;
          font-weight: 700;
          line-height: $len-m;
          height: $len-m;
          border: none;

          &.multiline {
            height: auto;
            line-height: revert;
          }

          &:not(.no-data-th):before {
            content: attr(data-th);
            color: $color-d-1;
            font-weight: 400;
            margin-right: $len-2xs;
          }

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

@media (max-width: $bp-s) {
  .table-with-sort {
    .table {
      .table-row {
        display: block;
      }
    }
  }
}
