@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-container-radio {
  &.vertical {
    .input-label {
      vertical-align: top;
    }
  }

  .radio-container {
    &.vertical {
      @include inputVertical();
    }

    .single-radio {
      margin-bottom: $len-xs;

      input {
        vertical-align: middle;
        margin-right: $len-2xs;
        cursor: pointer;
      }
    }
  }
}
