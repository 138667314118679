// colors
$color-a-1: #1E1826;
$color-a-2: #302C37;
$color-a-3: #5B5464;
$color-a-4: #342C40;
$color-a-5: #1B1325;
$color-a-6: #5A5A5A;

$color-b-1: #48257A;
$color-b-2: #5C2D9F;
$color-b-3: #F4EDFF;
$color-b-4: #6D4A9F;
$color-b-5: #E8DAFF;
$color-b-6: #F0ECF5;
$color-b-7: #5D4182;
$color-b-8: #FAF9FB;

$color-c-1: #E84141;
$color-c-2: #FFEDED;
$color-c-3: #D31E1E;
$color-c-4: #FFF2F2;
$color-c-5: #F4DFDF;

$color-d-1: #8f8f8f;
$color-d-2: #ECECEC;
$color-d-3: #F9F9F9;
$color-d-4: #F2F2F2;
$color-d-5: #B2B2B2;
$color-d-6: #F7F6FA;
$color-d-7: #E5E5E5;
$color-d-8: #FEFEFE;
$color-d-9: #8B9298;
$color-d-10: #A8A8A8;

$color-e-1: #32B17D;
$color-e-2: #209866;

$color-f-1: #006DD1;
$color-f-2: #015BAE;
$color-f-3: #2697FF;
$color-f-4: #C0E1FF;

$color-w-1: #fff;


$color-background-primary: #fff;
$color-neutral-1: grey;
$color-neutral-2: #8391ae;

$color-input-primary: $color-a-4;
$color-input-background: $color-w-1;
$color-input-hover: $color-d-2;
$color-input-disabled: #eee;
$color-input-disabled-2: #ccc;

$color-header-primary: #3b5998;
$color-header-secondary: #5f7ec1;

$color-element-head-primary: #7c96cc;

$color-error: #cc0000;
$color-success: #37ab73;

// text
$font-family: 'OpenSans', sans-serif;

$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 24px;

$font-weight-m: 400;
$font-weight-l: 700;

// sizes
$len-3xs: 4px;
$len-2xs: 8px;
$len-xs: 12px;
$len-s: 16px;
$len-sm: 20px;
$len-m: 24px;
$len-ml: 30px;
$len-l: 36px;
$len-xl: 48px;
$len-2xl: 58px;
$len-3xl: 96px;


$size-xs: 5px;
$size-s: 10px;
$size-m: 15px;
$size-l: 20px;
$size-xl: 25px;
$size-2xl: 30px;

$vertical-form-label-width: 300px;
$vertical-form-fields-container-width: 480px;

// borders and shadows
$border-radius-m: 3px;
$border-radius-l: 5px;

$shadow-standard: 0px 0px 2px 1px #ddd;

// breakpoints
$bp-l: 1270px;
$bp-m: 990px;
$bp-s: 670px;
$bp-xs: 530px;
