@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';
@import '../styles/_animations.scss';

.notifications {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  pointer-events: none;
  z-index: 50;

  .notification {
    margin: $len-sm auto 0;
    pointer-events: all;
    width: fit-content;
    padding: $len-s 50px;
    border-radius: 5px;
    position: relative;
    color: $color-a-4;
    opacity: 1;
    animation-name: popup;
    animation-duration: 300ms;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-size: $font-size-m;

    &.error {
      background-color: $color-c-4;
      width: 520px;

      .notification-close {
        &:hover {
          background-color: $color-c-5;
        }
      }
    }

    &.success {
      background-color: $color-w-1;
      max-width: 520px;

      .notification-content {
        padding-right: $len-2xs;
      }

      .notification-close {
        &:hover {
          background-color: $color-d-7;
        }
      }
    }

    &-close {
      position: absolute;
      right: 10px;
      top: 7px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      padding: 8px;

      &:hover {
        opacity: 1;
        border-radius: 50%;
      }

      &::before, &::after {
        content: ' ';
        background-color: $color-a-4;
        width: 2px;
        height: $len-sm;
        right: 16px;
        top: 7px;
        transform: rotate(45deg);
        position: absolute;
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    .error-icon {
      position: absolute;
      left: $len-s;
      top: 14px;
      background-color: $color-c-3;
      color: $color-w-1;
      font-style: normal;
      text-align: center;
      font-weight: 700;
      width: $len-sm;
      height: $len-sm;
      line-height: $len-sm;
      border-radius: 50%;
      display: block;
    }

    .success-icon {
      position: absolute;
      left: $len-s;
      top: 14px;
      background-color: $color-a-4;
      width: $len-sm;
      height: $len-sm;
      text-align: center;
      border-radius: 50%;
      display: block;

      &:after {
        content: '';
        transform: rotate(35deg);
        left: 7px;
        top: 4px;
        height: 7px;
        width: 4px;
        border-bottom: 2px solid $color-w-1;
        border-right: 2px solid $color-w-1;
        position: absolute;
      }
    }
  }
}
