@import 'styles/_constants.scss';

.invoice {
  .price-row {
    .table-cell {
      border-top: none !important;
      background-color: $color-w-1;
    }
  }

  .print-controls-container {
    text-align: right;
    margin-top: $len-m;
  }

  .total-price-row {
    font-weight: 700;
  }

  .price-row:not(.total-price-row) {
    font-weight: 400;
    .table-cell {
      font-weight: 400;
    }
  }

  .squashed {
    line-height: $len-m !important;
    padding-top: $len-3xs !important;
    padding-bottom: $len-3xs !important;
  }

  .top-align {
    vertical-align: top;
  }

  .price-breakdown-row {
    display: none;
  }

  .table-row.colored-background {
    background-color: $color-d-3;
  }
}

.invoice-print {
  padding: $len-l;
  line-height: 150%;

  .property-data {
    display: inline-block;
  }

  .guest-data {
    float: right;
    text-align: right;
  }

  .name {
    font-weight: 700;
  }

  .title {
    margin-top: $len-2xl;
  }

  .invoice-details {
    margin-bottom: $len-s;
  }

  .table {
    .table-row:first-child {
      border-top: 1px solid $color-d-7;

      &.price-row {
        .table-cell {
          border-top: none;
        }
      }
    }

    .price-row:not(.total-price-row) {
      font-weight: 400;
      .table-cell {
        font-weight: 400;
        line-height: $len-xs !important;
        height: $len-xs !important;
      }
    }
  }

  .greeting-container {
    margin-top: $len-2xl;
    text-align: right;
  }
}

@media (max-width: $bp-m) {
  .reset-mobile-alignment {
    text-align: left !important;
  }

  .price-row:not(.total-price-row) {
    color: $color-d-1;
    .table-cell {
      color: $color-d-1;
    }
  }

  .price-breakdown-row, .data-row {
    width: 50%;
    display: inline-block !important;
    vertical-align: bottom !important;

    .price-cell {
      display: none !important;
    }
  }
}

@media (max-width: $bp-s) {
  .invoice {

    .print-controls-container {
      padding-right: $len-s;

      .input-label {
        text-align: right;
        width: auto;
        padding-right: 0;
      }
    }
  }
}
