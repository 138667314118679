@import 'styles/_constants.scss';

.message {
  padding: $len-s;
  text-align: left;
  border-bottom: 1px solid $color-d-4;
  width: 245px;

  .message-subject {
    margin-bottom: $len-xs;
    font-weight: 700;
    color: $color-c-1;
  }

  .message-content {
    line-height: 16px;
  }

  .message-emphasized {
    font-weight: 700;
  }
}
