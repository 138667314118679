@import '../../client/node_modules/normalize.css/normalize.css';
@import '../../client/src/styles/constants';

@font-face {
  font-family: 'OpenSans';
  src: url('../../client/src/styles/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('../../client/src/styles/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  font-family: $font-family;
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-s;
  margin: 0;
  height: 100%;
  //position: relative;
  background: $color-w-1;
  color: #111;

  #root {
    min-height: 100%;
    display: flex;
  }

  #print {
    display: none;
  }
}

a {
  text-decoration: none;
}

button {
  border: none;
  font: inherit;

  &:focus {
    outline: none;
  }
}

@media print {
  body > div, body #root {
      display: none;
  }

  body > #print {
    display: block;
  }
}
