@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';
@import '../styles/_animations.scss';

.modal {
  opacity: 1;
  animation-name: popup;
  animation-duration: 300ms;
  z-index: 21;

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .5;
    background-color: #000;
    z-index: 20;
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;

  &-container {
    position: relative;
    display: block;
    align-self: center;
    overflow: auto;
    margin: 0 auto;
    background-color: $color-b-8;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);

    &.big {
      width: calc(100% - 160px);
      height: calc(100% - 60px);
    }

    &.medium {
      width: 950px;
      height: calc(100% - 160px);
    }

    &.small {
      width: 450px;
    }
  }

  &-content {
    margin: auto;
    height: 100%;
  }

  &-header {
    font-size: $font-size-l;
    overflow: hidden;
    padding: 0 $len-m;
    color: $color-w-1;
    position: relative;
    font-weight: 600;
    background-color: $color-b-7;
    height: 55px;
    line-height: 55px;
  }

  &-close {
    position: absolute;
    right: 12px;
    top: 8px;
    width: $size-xl;
    height: $size-xl;
    cursor: pointer;
    padding: 8px;
  }

  &-close:hover {
    opacity: 1;
    background-color: $color-b-1;
    border-radius: 50%;
  }

  &-close:before, &-close:after {
    position: absolute;
    right: 19px;
    content: ' ';
    height: $len-m;
    width: 3px;
    background-color: $color-w-1;
  }

  &-close:before {
    transform: rotate(45deg);
  }

  &-close:after {
    transform: rotate(-45deg);
  }

  &-body {
    padding: $len-m;
    height: calc(100% - 55px - 2 * #{$len-m});

    .tabs {
      margin: -($len-m);
      height: calc(100% + 2 * #{$len-m});
    }

    .modal-delete-container {
      position: absolute;
      bottom: $len-m;

      @include deleteTooltip();
    }

    .form.vertical {
      margin-left: calc(50% - #{$vertical-form-fields-container-width}/2);
    }
  }
}

@media (max-width: $bp-l) {
  .modal {
    .modal-container.big {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: $bp-m) {
  .modal {
    .modal-container.medium {
      width: 100%;
      height: 100%;
    }
    .modal-header {
      .modal-header-text {
        width: calc(100% - #{$len-m});
        @include ellipsis();
      }
    }
  }
}

@media (max-width: $bp-s) {
  .modal {
    .modal-body {
      .modal-delete-container {
        left: 0;
      }

      &>.form.vertical {
        margin-left: 0;

        .form-input-fields {
          left: -($len-m);
          right: -($len-m);
        }
      }
    }
  }
}
