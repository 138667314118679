@import '../styles/_constants.scss';

.dropdown {
  display: inline-block;
  position: relative;

  .dropdown-items-container {
    background: $color-w-1;
    position: absolute;
    right: 0;
    top: $len-2xl;
    z-index: 1;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    min-width: 130px;

    .dropdown-item {
      padding: $len-xs $len-s;
      cursor: pointer;
      color: $color-a-1;

      &:not(:last-child) {
        border-bottom: 1px solid $color-d-4;
      }

      &:hover {
        background-color: $color-d-3;
      }

      &.active {
        background-color: $color-d-3;
      }

      &.no-click {
        cursor: default;
      }
    }
  }
}