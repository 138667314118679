@import '../../../client/src/styles/constants';
@import '../../../client/src/styles/mixins';

.header {
  position: sticky;
  top: 0;
  background: $color-d-3;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $len-2xl;
  z-index: 10;
  border-bottom: 1px solid $color-d-2;
  box-sizing: border-box;

  .breadcrumbs {
    padding-left: $len-m;
    font-size: $font-size-m;
  }

  .menus {
    .add-reservation {
      margin-right: $len-xs;
    }

    .header-menu {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .no-notifications {
    color: $color-a-3;
    padding: $len-xs;
    text-align: right;
    min-width: 200px;
  }
}

.header-menu-item {
  display: inline-block;
  width: $len-2xl;
  height: $len-2xl;
  border: 0;
  border-left: 1px solid $color-d-2;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  position: relative;

  &:focus {
    background-color: $color-d-2;
  }

  &:hover [role=tooltip], &:focus [role=tooltip] {
    display: block;
  }

  [role=tooltip] {
    display: none;
    position: absolute;
    bottom: -$len-l;
    right: $len-2xs;
    background-color: $color-a-2;
    padding: $len-3xs $len-s;
    color: $color-w-1;
    border-radius: 3px;

    &:before {
      border: 7px solid transparent;
      border-bottom-color: $color-a-2;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      top: -14px;
      right: 10px;
    }
  }

  .message-count {
    position: absolute;
    color: $color-w-1;
    background-color: $color-c-1;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    line-height: 21px;
    border: 1px solid $color-w-1;
    font-size: $font-size-s;
    text-align: center;
    top: 8px;
  }
}

@media (max-width: $bp-l) {
  .breadcrumbs span {
    display: none;
  }
}
@media (max-width: $bp-m) {
  .add-reservation {
    display: none;
  }
}
