@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-matrix-container {
    margin-bottom: $size-m;

    .input-label {
        display: block;
        margin-bottom: $size-s;
    }

    .input-matrix {
        th, td {
            text-align: left;
        }

        th {
            padding: $len-2xs 0;
        }

        &-label-cell {
            padding-right: $size-l;
        }

        .checkbox-container {
            .checkmark {
                top: 10px;
            }
        }
    }

    .input-matrix-add-button {
        margin-top: $size-s;
    }

    .delete-cell {
        padding-left: 10px !important;

      .button.delete-link {
          padding: 0;

          .delete {
              vertical-align: middle;
              margin: 0;
          }
      }
    }
}
