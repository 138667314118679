@import '../styles/_constants.scss';

.page {
  padding: $len-s;
  height: calc(100vh - #{$len-2xl} - 2 * #{$len-s});
  display: flex;
  flex-direction: column;
  max-width: calc(1500px - 2 * #{$len-s});
  margin: 0 auto;

  &.full-width {
    max-width: unset;
  }

  .page-header {
    margin-bottom: $len-s;
  }

  .page-content {
    flex: 1;
    max-height: 100%;

    &.with-header {
      max-height: calc(100% - #{$len-l} - #{$len-s});
    }

    &.full-height {
      height: 100%;
    }

    &.scrollable {
      overflow-y: auto;
    }

    .form.vertical {
      margin-left: calc(50% - #{$vertical-form-fields-container-width}/2);
    }

    .tabs {
      margin-top: -($len-s);
      margin-bottom: -($len-s);
      margin-left: -($len-m + $len-3xs);
      margin-right: -($len-m + $len-3xs);
      height: calc(100% + 2 * #{$len-m} - #{$len-s});
    }
  }
}

@media (max-width: $bp-s) {
  .page {
    padding-left: 0;
    padding-right: 0;
    width: calc(100% - 2 * #{$len-s});

    .page-content {
      &>.tabs {
        margin-left: -($len-s);
        margin-right: -($len-s);
      }

      &>.form.vertical {
        margin-left: -($len-s);
        margin-right: -($len-s);
        width: calc(100% + 2 * #{$len-s});

        .form-controls-container {
          left: 24px;
          width: calc(100% - 2 * 24px);
        }
      }

      &>.table, &>.table-with-sort {
        margin-left: -($len-s);
        margin-right: -($len-s);
      }
    }
  }
}
