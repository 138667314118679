@import 'styles/_constants.scss';

@mixin inputContainerHorizontal() {
  display: inline-block;
  vertical-align: bottom;
  margin-right: $size-m;

  @media (max-width: $bp-m) {
    display: block;
    margin-bottom: $len-2xs;
    margin-right: 0;
  }
}

@mixin inputContainerVertical() {
  &:not(:last-child) {
    margin-bottom: $size-m;
  }
}

@mixin inputContainerStacked() {
  margin-bottom: $size-m;
}

@mixin inputLabelVertical() {
  display: inline-block;
  width: calc(165px - #{$len-ml} - #{$len-xs});
  vertical-align: middle;
  padding-right: $len-xs;
  text-align: right;

  @media (max-width: $bp-xs) {
    display: block;
    text-align: left;
    margin-bottom: $len-2xs;
  }
}

@mixin inputValidationVertical() {
  margin-left: calc(165px - #{$len-ml});
}

@mixin inputVertical() {
  display: inline-block;
  vertical-align: middle;
  width: calc(250px - #{$len-s});
}

@mixin inputStacked() {
  margin-top: $size-s;
}

@mixin inputWithError() {
  border-width: 2px;
  border-color: $color-error;
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin visuallyHidden() {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin textInput() {
  height: $len-l;
  line-height: $len-l;
  width: calc(250px - #{$len-s});
  background-color: $color-w-1;
  border: 1px solid $color-a-6;
  border-radius: 2px;
  font-family: 'OpenSans', sans-serif;
  font-size: $font-size-m;
  font-weight: 600;
  padding: 0 $len-s;
  box-sizing: border-box;
  position: relative;

  &::placeholder {
    font-weight: 400;
  }

  &[disabled] {
    background-color: $color-d-2;
    color: $color-d-9;
  }

  &:focus, &:hover {
    outline: none;
    &:not(:hover) {
      border-color: $color-f-3;
    }
  }

  &.error {
    @include inputWithError();
    background-color: $color-c-2;
  }
}

@mixin deleteTooltip() {
  &:hover [role=tooltip], &:focus [role=tooltip] {
    display: block;
  }

  [role=tooltip] {
    display: none;
    position: absolute;
    bottom: calc(50% - 12px);
    left: 100%;
    right: -250px;
    background-color: $color-a-2;
    padding: $len-3xs $len-s;
    color: $color-w-1;
    border-radius: 3px;
    line-height: initial;

    &:before {
      border: 7px solid transparent;
      border-right-color: $color-a-2;
      content: "";
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      bottom: 5px;
      left: -14px;
    }
  }
}
