@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-list-container { 
    .input-list {
        width: 280px;
        font-size: 14px;
        padding: 0 15px;

        &.vertical {
            @include inputVertical();
        }

        &-item {
            margin-bottom: $size-m;
        }

        &-button-delete-item {
            margin-top: $size-m;
        }
    }

    &.horizontal {
        @include inputContainerHorizontal();
    }

    &.vertical {
        @include inputContainerVertical();

        .input-label {
            vertical-align: top;
        }
    }

    .input-list-add-button {
        margin-top: $size-m;
    }
}
