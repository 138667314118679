@import 'styles/_constants.scss';
@import 'styles/_mixins.scss';

$calendar-row-height: 52px;

.calendar {
  position: relative;
  height: 100%;

  .room-rows-container {
    overflow: auto;
    position: absolute;
    top: $calendar-row-height;
    border-bottom: 1px solid $color-d-5 !important;
    max-height: calc(100% - #{$calendar-row-height});
  }

  .row {
    box-sizing: border-box;
    height: $calendar-row-height;
    overflow: hidden;

    &:last-child {
      .name-column-cell {
        border-bottom: none;
      }
    }
  }

  .cell {
    box-sizing: border-box;
    height: 52px;
    display: inline-block;
    vertical-align: bottom;

    &.before-active-cell {
      border-top: 1px solid $color-a-2;
      border-bottom: 1px solid $color-a-2 !important;
    }

    &.above-active-cell {
      border-left: 1px solid $color-a-2;
      border-right: 1px solid $color-a-2;
    }

    &.active-cell {
      border: 2px solid $color-b-2;
      cursor: pointer;
    }

    .calendar-plus {
      margin-left: calc(50% - 9px);
      margin-top: calc(50% - 9px);
      display: inline-block;
    }

    &:last-of-type {
      border-right: 1px solid $color-d-5 !important;
    }
  }

  .room-row {
    .room-cells-container {
      display: inline-block;
      position: relative;

      .cell {
        background-color: $color-d-6;
      }

      .room-cell {
        &:not(.active-cell):not(.before-active-cell) {
          border-bottom: 1px solid $color-d-2;
        }

        &:not(.active-cell):not(.above-active-cell) {
          border-right: 1px solid $color-d-2;
        }
      }
    }
  }

  .unit-row {
    .unit-cells-container {
      display: inline-block;
      position: relative;

      .unit-cell {
        &:not(.active-cell):not(.above-active-cell) {
          border-right: 1px solid $color-d-2;
        }

        &:not(.active-cell):not(.before-active-cell) {
          border-bottom: 1px solid $color-d-2;
        }
      }
    }
  }

  .calendar-header {
    position: relative;

    .date-nav {
      &.previous {
        position: absolute;
        left: 162px;
        top: 15px;
      }

      &.next {
        position: relative;
        left: -10px;
        top: -15px;
      }
    }

    .header-cell {
      background-color: $color-d-3;
      border: 1px solid $color-d-5;
      border-left: 0;
      text-align: center;
      padding-top: $len-3xs;

      &.above-active-cell {
        border-right: 1px solid $color-a-2;
        border-left: 1px solid $color-a-2;
        border-top: 1px solid $color-a-2;
      }

      .header-cell-day {
        font-size: $font-size-xs;
        margin-top: $len-3xs;
        color: $color-d-1;
      }

      .header-cell-date {
        font-size: $font-size-l;
        font-weight: 700;
      }

      .header-cell-month {
        font-size: $font-size-xs;
        color: $color-d-1;
      }

      .header-cell-first-in-month {
        height: $len-3xs;
        background-color: $color-a-2;
        margin-top: -$len-3xs;
      }
    }
  }

  .name-column-cell {
    width: 172px;
    padding: 0 $len-m;
    line-height: 52px;
    font-weight: 700;
    border: 1px solid $color-d-5;
    border-top: 0;

    &.room-name-column-cell {
      cursor: pointer;
      position: relative;

      .calendar-room-arrow {
        position: absolute;
        left: $len-2xs;
      }
    }

    &.before-active-cell {
      border-top: 1px solid $color-a-2;
      border-bottom: 1px solid $color-a-2;
      border-left: 1px solid $color-a-2;
    }

    &.header-cell {
      text-transform: uppercase;
      color: $color-d-1;
      text-align: left;
      border: 1px solid $color-d-5;
      padding-top: 0;
      font-weight: 400;
    }
  }

  .calendar-button-wrapper {
    position: absolute;
    box-sizing: border-box;
    display: block;

    .calendar-button-wrapper-inner {
      overflow: hidden;
      display: block;
    }

    &.calendar-button-wrapper-rate {
      top: 14px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);

      .calendar-rate {
        background-color: $color-a-2;
        color: $color-w-1;
        height: $len-m;
        line-height: $len-m;
        padding: 0 $len-2xs;
        cursor: pointer;
        @include ellipsis();
        text-align: left;
        display: initial;
        width: 100%;
        box-sizing: border-box;

        &:hover, &:focus {
          background-color: $color-a-5;
        }
      }

      &:hover [role=tooltip], &:focus-within [role=tooltip] {
        display: block;
      }

      [role=tooltip] {
        display: none;
        position: absolute;
        top: -10px;
        left: calc(100% - 30px);
        background-color: $color-w-1;
        padding: $len-3xs $len-s;
        color: $color-a-2;
        border: 1px solid $color-a-2;
        border-radius: 3px;
        line-height: initial;
        font-size: $font-size-s;
        z-index: 1;
        white-space: nowrap;

        &.indented-tooltip {
          left: calc(100% - 10px);
        }

        &.right-tooltip {
          left: auto;
          right: 10px;
        }

        &.bottom-tooltip {
          top: auto;
          bottom: -10px;
        }
      }
    }

    &.calendar-button-wrapper-reservation {
      top: 7px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      .calendar-reservation {
        background-color: $color-b-3;
        border-left: 8px solid $color-b-4;
        height: $len-l;
        line-height: $len-l;
        padding: 0 $len-2xs;
        cursor: pointer;
        box-sizing: border-box;
        @include ellipsis();
        width: 100%;
        text-align: left;

        &:hover, &:focus {
          background-color: $color-b-5;
        }
      }

      &.overbooked-reservation {
        top: 3px;

        .calendar-reservation {
          height: $len-sm;
          line-height: $len-sm;
          background-color: $color-c-2;
          border-left: 8px solid $color-c-1;
        }

        &.bottom-overbooked-reservation {
          top: calc(#{$len-sm} + 7px);
        }
      }

      &:hover [role=tooltip], &:focus-within [role=tooltip] {
        display: block;
      }

      [role=tooltip] {
        display: none;
        position: absolute;
        top: -5px;
        left: calc(100% - 30px);
        background-color: $color-w-1;
        padding: $len-3xs $len-s;
        color: $color-a-2;
        border: 1px solid $color-a-2;
        border-radius: 3px;
        line-height: initial;
        font-size: $font-size-s;
        z-index: 1;
        white-space: nowrap;

        &.indented-tooltip {
          left: calc(100% - 10px);
        }

        &.right-tooltip {
          left: auto;
          right: 10px;
        }
      }

    }

    &.calendar-button-wrapper-unavailability {
      top: 0;

      .calendar-unavailability {
        background: repeating-linear-gradient(-70deg, $color-d-3, $color-d-3 17px, $color-d-2 17px, $color-d-2 34px,);
        border: 1px solid $color-d-2;
        border-bottom: 2px solid $color-d-2;
        border-right: 2px solid $color-d-2;
        height: $calendar-row-height;
        line-height: $calendar-row-height;
        padding: 0 $len-2xs;
        text-transform: uppercase;
        cursor: pointer;
        box-sizing: border-box;
        @include ellipsis();
        width: 100%;
        text-align: left;

        &:hover, &:focus {
          background: repeating-linear-gradient(-70deg, $color-d-2, $color-d-2 17px, $color-d-7 17px, $color-d-7 34px,);
        }
      }
    }
  }
}

.add-reservation-or-unavailability {
  button {
    margin-bottom: $len-m;
  }
}

@media (max-width: $bp-m) {
  .calendar {
    .name-column-cell {
      width: 100px;
      @include ellipsis();
    }

    .calendar-header {
      .date-nav {
        &.previous {
          left: 90px;
        }
      }
    }
  }
}
