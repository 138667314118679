@import '../styles/constants';

.skeleton {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex: 1 1 100%;

  .skeleton-vertical-navigation {
    position: sticky;
    top: 0;
    bottom: 0;
    width: 140px;
    background: $color-d-7;
  }

  .skeleton-container {
    flex-grow: 1;
    background-color: $color-b-8;
    overflow: hidden;

    .skeleton-header {
      background: $color-d-2;
      border: none;
      height: $len-2xl;
    }

    .skeleton-page-content {
      position: relative;
      height: 100%;
      display: flex;
      align-items: stretch;
      padding: $len-s;

      .skeleton-widget-column {
        width: calc(50% - 2 * #{$len-s});
        vertical-align: top;
        overflow-y: auto;
        margin: $len-s;
        display: flex;
        flex-direction: column;
      }

      .skeleton-widget {
        flex: 0 1 auto;
        background: $color-d-2;
        height: calc(50% - 4 * #{$len-s});
        margin-bottom: 2 * $len-s;
      }

      .skeleton-widget-small {
        flex: 0 1 auto;
        background: $color-d-2;
        height: calc(20% - 2 * #{$len-s});
        margin-bottom: 2 * $len-s;
      }
    }
  }
}