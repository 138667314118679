@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.search-input-text-container {
  position: relative;

  .input-text {
    @include textInput();

    &.vertical {
      @include inputVertical();
    }

    &.stacked {
      @include inputStacked();
    }
  }

  &.horizontal {
    @include inputContainerHorizontal();
  }

  &.vertical {
    @include inputContainerVertical();
  }

  &.stacked {
    @include inputContainerStacked();
  }

  .search-input-results {
    position: absolute;
    left: calc(165px - #{$len-ml});
    width: calc(250px - #{$len-s});
    z-index: 1;
    border-left: 1px solid $color-d-5;
    border-right: 1px solid $color-d-5;
    box-sizing: border-box;

    .search-input-result-line {
      padding: $len-2xs;
      background-color: $color-d-8;
      border-bottom: 1px solid $color-d-5;
      cursor: pointer;

      &:hover {
        background-color: $color-b-6;
      }
    }
  }
}
