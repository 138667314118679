@import 'styles/_constants.scss';

.payments-header {
  margin-bottom: $len-s;
}

@media (max-width: $bp-s) {
  .payments-header {
    padding-left: $len-s;
  }
}
