@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';


.checkbox-container {
  display: block;
  cursor: pointer;
  user-select: none;
  height: 36px;

  .checkbox-input-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 36px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark {
        background-color: $color-f-1;
        border-color: $color-f-1;

        &:after {
          display: block;
        }

        &:hover, &:focus {
          background-color: $color-f-4;
          border-color: $color-f-4;
        }
      }

      &:focus {
        outline: none;
      }

      &.vertical {
        @include inputVertical();
      }
    }

    .checkmark {
      position: absolute;
      top: 8px;
      left: 0;
      height: 21px;
      width: 21px;
      background-color: transparent;
      border: 1px solid $color-d-9;
      box-sizing: border-box;
      border-radius: 3px;
      cursor: pointer;

      &:hover, &:focus {
        border-color: $color-f-1;

        &:after {
          display: block;
        }
      }

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 3px;
        width: 4px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      &.small {
        height: 14px;
        width: 14px;
        border-radius: 7px;

        &:after {
          left: 4px;
          top: 1px;
          width: 4px;
          height: 8px;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  &.secondary {
    .checkbox-input-container {
      input {
        &:checked ~ .checkmark {
          background-color: $color-e-1;
          border-color: $color-e-2;

          &:hover, &:focus {
            background-color: rgba(50, 177, 125, 0.2);
          }
        }

        &.vertical {
          @include inputVertical();
        }
      }

      .checkmark {
        height: 18px;
        width: 18px;
        background-color: $color-w-1;
        border: 1px solid $color-d-1;
        border-radius: 9px;

        &:hover {
          background-color: rgba(50, 177, 125, 0.2);
          border-color: $color-e-2;
        }

        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 5px;
          top: 1px;
          width: 4px;
          height: 9px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }

        &.small {
          height: 14px;
          width: 14px;
          border-radius: 7px;

          &:after {
            left: 4px;
            top: 1px;
            width: 4px;
            height: 8px;
            border-width: 0 2px 2px 0;
          }
        }
      }
    }
  }

  &.horizontal {
    @include inputContainerHorizontal();

    .checkmark {
      top: 1px;
    }
  }

  &.vertical {
    @include inputContainerVertical();

    .checkbox-label {
      @include inputLabelVertical()
    }
  }

  &.in-table {
    line-height: 18px;
    height: 18px;

    .checkbox-input-container {
      position: relative;
      display: block;
      height: 18px;

      .checkmark {
        top: 0;
      }
    }
  }

  .tooltip-container {
    margin-left: $len-ml;
  }
}

@media (max-width: $bp-xs) {
  .checkbox-container {
    &.vertical {
      margin-bottom: $len-m !important;

      .checkbox-label {
        width: auto;
      }

      .checkbox-input-container {
        .checkmark {
          top: 0;
        }
      }
    }
  }
}
