@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-group-container { 
    .input-group-label {
        display: block;
        font-weight: $font-weight-l;
        font-size: $font-size-l;
        margin-bottom: $size-s;
    }

    .input-group {
        width: 280px;
        font-size: 14px;
        padding: 0 15px;

        &.vertical {
            @include inputVertical();
        }

        &.stacked {
            @include inputStacked();
        }

        &-item {
            margin-bottom: $size-m;
        }
    }

    &.horizontal {
        @include inputContainerHorizontal();
    }

    &.vertical {
        @include inputContainerVertical();

        .input-label {
            vertical-align: top;
        }
    }

    &.stacked {
        @include inputContainerStacked();
    }

    .input-list-add-button {
        margin-top: $size-m;
    }
}
