@import 'styles/_constants.scss';

.login {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-d-3;

  .login-content {
    position: absolute;
    top: calc(50% - 170px);
    bottom: calc(50% - 170px);
    left: calc(50% - 250px);
    right: calc(50% - 250px);
    background-color: $color-w-1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .login-form-header {
      height: 45px;
      line-height: 45px;
      background-color: $color-a-5;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-bottom: 60px;

      img {
        vertical-align: middle;
        margin-left: calc(50% - 52px);
      }
    }
  }

  .input-text {
    width: 280px !important;
  }

  .button {
    float: right;
    margin-right: 85px;
    margin-top: 5px;
    text-transform: uppercase;
    min-width: 135px;
  }

  .validation-error {
    margin-right: 85px;
    margin-top: 45px;
    text-align: right;

    div {
      display: inline;
    }
  }

  .success-container {
    margin: 0 85px;
    text-align: center;
    font-size: 14px;

    div {
      margin-bottom: 10px;
    }

    .button {
      float: none;
      margin-right: 0;
    }
  }

  .forgot-password {
    margin-left: 135px;
    margin-right: 85px;
    margin-top: -10px;

    a {
      color: $color-d-5;

      &:focus, &:hover:not(:disabled) {
        color: $color-d-1;
      }
    }
  }

  &.reset-password, &.request-password {
    .validation-error {
      margin-top: 60px;
    }
  }
}

.login-extra-controls {
  position: absolute;
  top: calc(50% + 175px);
  left: calc(50% - 240px);
  right: calc(50% - 240px);

  .login-language {
    float: right;

    svg {
      margin-right: $len-3xs;
      vertical-align: middle;
    }

    select {
      appearance: none;
      background: none;
      border: none;
      outline: none;
      color: $color-d-5;
      font-size: $font-size-s;
      font-family: $font-family;
      vertical-align: middle;
      cursor: pointer;

      &:focus, &:hover {
        color: $color-d-1;
      }
    }
  }
}

@media (max-width: $bp-s) {
  .login {
    background-color: $color-w-1;

    .login-content {
      position: unset;
      border-radius: 0;
      padding: 0 $len-sm;

      .login-form-header {
        border-radius: 0;
        margin-left: -$len-sm;
        margin-right: -$len-sm;
      }

      .input-text-container {

        label {
          display: block;
          text-align: left;
          width: auto;
          margin-bottom: $len-2xs;
        }
        input {
          display: block;
          width: 100% !important;
        }
      }

      .forgot-password {
        margin: 0;
      }

      button {
        margin-right: 0;
      }
    }

    .validation-error {
      margin-right: 0;
    }
  }

  .login-extra-controls {
    top: $len-s;
    left: auto;
    right: $len-2xs;
  }
}

