@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-text-container {
  position: relative;

  .input-text {
    @include textInput();

    &.vertical {
      @include inputVertical();
    }

    &.stacked {
      @include inputStacked();
    }
  }

  &.horizontal {
    @include inputContainerHorizontal();
  }

  &.vertical {
    @include inputContainerVertical();
  }

  &.stacked {
    @include inputContainerStacked();
  }
}
