@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';
@import '../styles/_animations.scss';

.button {
  height: 36px;
  background-color: $color-b-2;
  border-radius: 3px;
  font-family: $font-family;
  font-size: $font-size-m;
  padding: 10px 20px;
  display: inline-block;
  color: $color-w-1;
  font-weight: 600;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus, &:hover {
    outline-color: transparent;
    background-color: $color-b-1;
  }

  &.small {
    font-size: $font-size-xs;
    padding: 0;
  }

  &.full {
    width: 100%;
  }

  &.primary {
    text-transform: revert;
    font-size: $font-size-s;
  }

  &.secondary {
    background-color: $color-w-1;
    color: $color-a-6;
    border: 1px solid $color-a-6;

    &:focus, &:hover:not(:disabled) {
      background-color: $color-d-2;
    }

    &:disabled {
      color: $color-d-9;
    }
  }

  &.tertiary {
    text-transform: revert;
    font-size: $font-size-s;
    background-color: $color-w-1;
    color: $color-a-6;
    border: 1px solid $color-a-6;

    &:focus, &:hover:not(:disabled) {
      background-color: $color-d-2;
    }
  }

  &.action {
    background-color: $color-a-1;

    &:focus, &:hover:not(:disabled) {
      background-color: $color-a-2;
    }
  }

  &.link {
    border: none;
    background-color: transparent;
    color: $color-a-4;

    &:focus, &:hover:not(:disabled) {
      color: $color-a-6;
    }

    &:disabled {
      color: $color-d-9;
    }

    &.success {
      &:focus, &:hover {
        background-color: transparent !important;
      }

      .success-icon {
        margin-left: $len-2xs;
        &:after {
          border-bottom: 3px solid $color-e-2;
          border-right: 3px solid $color-e-2;
        }
      }
    }
  }

  &.delete-link {
    border: none;
    background-color: transparent;
    color: $color-a-6;

    &:focus, &:hover:not(:disabled) {
      color: $color-c-3;

      .icon.delete {
        svg {
          path {
            fill: $color-c-3;
          }
        }
      }
    }
  }

  &.primary-link {
    border: none;
    background-color: transparent;
    color: $color-f-1;
    padding-left: $len-2xs;
    padding-right: $len-2xs;

    &:focus, &:hover:not(:disabled) {
      color: $color-f-2;

      .icon.plus {
        svg {
          path {
            fill: $color-f-2;
          }
        }
      }
    }
  }

  &.save {
    background-color: $color-f-1;
    min-width: 120px;
    white-space: nowrap;

    &:focus, &:hover:not(:disabled) {
      outline-color: transparent;
      background-color: $color-f-2;
    }

    &[disabled=true] {
      background-color: $color-f-2;
    }
  }

  &.confirm {
    background-color: $color-c-1;

    &:focus, &:hover:not(:disabled) {
      background-color: $color-c-3;
    }

    &[disabled=true] {
      background-color: $color-c-3;
    }
  }

  &.height-s {
    height: 20px;
  }

  &.horizontal {
    @include inputContainerHorizontal();
  }

  &.submitting {
    position: relative;
    background-color: $color-f-2;
    text-transform: none;
    font-weight: 400;
    cursor: not-allowed;

    .submitting-icon {
      display: inline-block;
      width: 21px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        border: 3px solid $color-w-1;
        border-color: $color-w-1 $color-f-2 $color-w-1 $color-w-1;
        animation: spin 1.2s linear infinite;
        position: absolute;
        left: 0;
        top: -14px;
      }
    }
  }

  &.success {
    position: relative;
    background-color: $color-e-2;

    &:focus, &:hover {
      background-color: $color-e-2 !important;
    }

    .success-icon {
      display: inline-block;
      width: 16px;
      position: relative;

      &:after {
        content: '';
        transform: rotate(35deg);
        position: absolute;
        left: 0;
        top: -13px;
        height: 9px;
        width: 5px;
        border-bottom: 3px solid $color-w-1;
        border-right: 3px solid $color-w-1;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
