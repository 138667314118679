.booking-limitations-form {
    .input-matrix {
        th:not(:first-child) {
            width: 40px;
        }
    }
}

.rate-rooms-form {
    .input-matrix {
        th:first-child {
            width: 100px;
        }
        th:nth-child(2) {
            width: 80px;
        }
    }
}
