@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';


.input-container-select {

    .select-container {
        position: relative;
        width: 280px;
        height: 36px;

        &.vertical {
            @include inputVertical();
        }

        .input-select {
            -webkit-appearance: none;
            @include ellipsis();
            @include textInput();
            padding-right: 36px;

        }

        .input-dropdown-arrow {
            position: absolute;
            right: $len-s;
            top: calc(50% - 8px);
            pointer-events: none;
        }
    }

    .select-no-value-warning {
        margin-top: $size-s;
        color: $color-error;
    }

    &.vertical {
        @include inputContainerVertical();

        .select-no-value-warning {
            margin-left: $vertical-form-label-width;
            width: 280px;
        }
    }

    &.horizontal {
        @include inputContainerHorizontal();

        .select-container {
            .input-dropdown-arrow {
                right: $len-2xl;
            }
        }
    }
}
