@import '../styles/_constants.scss';

#tooltips {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  pointer-events: none;
  z-index: 40;
}

.tooltip-container {
  display: inline-block;
  margin-left: $len-2xs;

  .icon.help {
    svg {
      vertical-align: text-top;
      cursor: help;
    }
  }

  .icon.help-small {
    svg {
      vertical-align: text-top;
      cursor: help;
      height: 14px;
      width: 14px;
    }
  }
}

.tooltip {
  position: absolute;
  background-color: $color-d-2;
  padding: $len-xs $len-s;
  border-radius: 3px;
  max-width: 250px;
  pointer-events: all;
  line-height: 16px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  &:before {
    border: 7px solid transparent;
    border-right-color: $color-d-2;
    content: "";
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    left: -14px;
    top: 10px;
  }
}

@media (max-width: $bp-s) {
  .tooltip-container {
    display: none;
  }
}
