@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.validation-error {
  margin-top: $len-3xs;

  &:not(.base-color) {
    color: $color-c-3;
  }

  .error-icon {
    background-color: $color-c-3;
    color: $color-w-1;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    display: inline-block;
    width: $len-s;
    height: $len-s;
    line-height: $len-s;
    margin-right: $len-3xs;
    border-radius: 50%;
  }

  &.vertical {
    @include inputValidationVertical();
  }
}

.form-error-container {
  .validation-error.vertical {
    margin-left: 0;
  }
}
