@import '../styles/_constants.scss';

.private-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex: 1 1 100%;

  .private-container {
    flex-grow: 1;
    background-color: $color-b-8;
    overflow: hidden;
  }
}

@media (max-width: $bp-s) {
  .private-layout {
    max-width: 100%;
  }
}
