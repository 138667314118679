@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';


.form {
  &.horizontal {
    display: inline-block;
    vertical-align: bottom;
  }

  &.vertical {
    width: $vertical-form-fields-container-width;
    height: 100%;
    position: relative;

    .form-input-fields {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-y: auto;

      .form-section {
        background-color: $color-w-1;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
        border-radius: $border-radius-m;;
        padding: $len-ml;

        &:not(:last-child) {
          margin-bottom: $len-xs;
        }
      }
    }

    .form-controls-container {
      position: absolute;
      top: 0;
      left: $vertical-form-fields-container-width + $len-s;
      width: 300px;
    }
  }

  .form-input-fields {
    &.columns {
      columns: 2;
    }
  }
}

@media (max-width: $bp-l) {
  .form {
    &.vertical {
      .form-controls-container {
        width: 180px;
      }
    }
  }
}

@media (max-width: $bp-m) {
  .form {
    &.vertical {
      .form-input-fields {
        bottom: 60px;
      }

      .form-controls-container {
        width: 100%;
        left: 0;
        top: auto;
        bottom: 0;
        text-align: right;
      }
    }

    &.horizontal {
      display: block;
    }
  }
}

@media (max-width: $bp-s) {
  .form {
    &.vertical {
      width: 100%;
    }
  }
}
