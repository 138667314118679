@import '../styles/_constants.scss';

.widget {
  margin-bottom: 2 * $len-s;
  width: 100%;
  background: $color-background-primary;
  border-top-right-radius: $border-radius-l;
  border-top-left-radius: $border-radius-l;

  .widget-header {
    border-top-right-radius: $border-radius-l;
    border-top-left-radius: $border-radius-l;
    padding: 0 $len-m;
    color: $color-w-1;
    position: relative;
    font-weight: 500;
    font-size: $font-size-m;
    text-transform: uppercase;
    background-color: $color-b-7;
    height: 45px;
    line-height: 45px;
    box-sizing: border-box;

    .tooltip-container {
      line-height: 24px;
      position: relative;
      font-size: 12px;
    }
  }

  .widget-content {
    height: calc(100% - 45px);
  }

  &.secondary-widget {
    .widget-header {
      background-color: $color-w-1;
      color: $color-a-2;
      border: 1px solid $color-d-5;
    }
  }
}

