@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.confirmation {
  width: 450px;

  .modal-header {
    background-color: $color-d-4;
    height: 47px;

    .modal-close {
      width: 18px;
      height: 18px;

      &:hover {
        background-color: $color-d-7;
      }

      &:before, &:after {
        background-color: $color-a-4;
        width: 2px;
        height: $len-sm;
        right: 16px;
        top: 7px;
      }
    }
  }

  .confirmation-text {
    margin-bottom: $len-s;
    min-height: 80px;
    font-size: $font-size-l;
  }

  .confirmation-buttons-container {
    text-align: right;

    .cancel-confirmation {
      vertical-align: bottom;
      margin-right: $size-m;
    }
  }
}

@media (max-width: $bp-s) {
  .confirmation {
    .confirmation-buttons-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .cancel-confirmation {
        margin-right: 0;
        margin-bottom: $size-m;
      }
    }
  }
}
