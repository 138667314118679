@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-number-container {
  .input-number {
    @include textInput();

    &.vertical {
      @include inputVertical();
      width: calc(100px - #{$len-s});
    }
  }

  .suffix {
    margin-left: $size-xs;
  }

  &.horizontal {
    @include inputContainerHorizontal();
  }

  &.vertical {
    @include inputContainerVertical();
  }
}
