@import '../styles/_mixins.scss';
@import '../styles/_constants.scss';

.input-label {
    font-size: $font-size-m;
    color: $color-a-6;

    &.vertical {
        @include inputLabelVertical();
    }
}
