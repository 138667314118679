@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';


.input-file-container {
  .input-file {
    height: 36px;
    line-height: 36px;
    background-color: $color-w-1;
    color: $color-a-4;
    border: 1px solid $color-a-4;
    border-radius: 3px;
    font-weight: 600;
    cursor: pointer;
    padding: 0 20px;

    &:hover, &:focus {
      background-color: $color-d-2;
    }

    &:focus {
      outline: none;
    }

    input[type="file"] {
      display: none;
    }

    &.vertical {
      @include inputVertical();
      width: auto !important;
      text-align: center;
    }

    &.stacked {
      @include inputStacked();
    }

    &.error {
      @include inputWithError();
    }

    &.disabled {
      background-color: $color-d-7;

      &:focus, &:hover {
        border-color: $color-a-6;
        outline: none;
        cursor: not-allowed;
      }
    }
  }

  &.horizontal {
    @include inputContainerHorizontal();
  }

  &.vertical {
    @include inputContainerVertical();
  }

  &.stacked {
    @include inputContainerStacked();
  }

  .button.link.small {
    margin-left: $len-xs;
  }

  .upload-progress {
    width: 60px;
    display: inline-block;
    margin-left: $len-xs;
  }
}

.files-preview-container {
  width: 100%;
  height: 100%;
  position: relative;

  .files-preview-content-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: $len-xs;

    .file-input-image-preview {
      padding: $len-2xs;
      border: 1px solid $color-d-5;
      height: 400px;
      width: 400px;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.to-delete {
          opacity: 0.5;
        }
      }

      .file-controls {
        position: absolute;
        bottom: $len-xs;
        right: $len-xs;
        height: $len-ml;
        width: $len-ml;
        background-color: rgba(256,256,256,0.8);
        border-radius: 3px;

        .checkbox-container {
          margin-left: $len-3xs;
          margin-top: -($len-3xs);

          .checkbox-input-container {
            input {
              &:checked ~ .checkmark {
                &:hover, &:focus {
                  border-color: $color-f-1;

                  &:after {
                    border-color: $color-f-1;
                  }
                }
              }
            }

            .checkmark {
              &:hover, &:focus {
                &:after {
                  border-color: $color-f-1;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $bp-s) {
  .files-preview-container {
    .files-preview-content-container {
      .file-input-image-preview {
        height: auto;
        width: 100%;
      }
    }
  }
}
