@import '../styles/_constants.scss';

.progress-bar {
  width: 100%;
  height: 7px;
  background-color: $color-d-2;
  border-top-right-radius: $border-radius-l;
  border-top-left-radius: $border-radius-l;

  .progress-bar-progress {
    background-color: $color-b-2;
    height: 100%;
    border-top-right-radius: $border-radius-l;
    border-top-left-radius: $border-radius-l;
  }

  &.upload {
    border-bottom-right-radius: $border-radius-l;
    border-bottom-left-radius: $border-radius-l;

    .progress-bar-progress {
      border-bottom-right-radius: $border-radius-l;
      border-bottom-left-radius: $border-radius-l;
    }
  }

}
