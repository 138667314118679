@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.tabs {
  .tabs-menu {
    height: 60px;
    line-height: 60px;
    background-color: $color-w-1;
    font-size: $font-size-m;
    text-transform: uppercase;
    color: $color-a-6;
    padding: 0 $len-m;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;

    &.vertical {
      display: inline-block;
      margin-right: $size-2xl;
    }

    .tab {
      display: inline-block;
      padding: 0 $len-sm;
      cursor: pointer;
      flex: 0 0 auto;

      &.vertical {
        display: block;
        padding-left: 0;
        border-right: 2px solid $color-header-primary;
      }

      &:hover {
        color: $color-b-4;
      }

      &.active-tab {
        box-shadow: inset 0px -4px 0px 0px $color-b-4;
        color: $color-b-4;
        font-weight: 700;
        cursor: default;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .tabs-add {
      line-height: initial;
      text-transform: initial;
      margin-right: -$len-m;
      margin-left: auto;
    }
  }

  .tab-content {
    height: calc(100% - 60px);
    position: relative;

    .tab-delete-container, .tab-actions-container {
      position: absolute;
      bottom: 0;

      @include deleteTooltip();
    }

    &.vertical {
      display: inline-block;
      vertical-align: top;
    }

    .form.vertical {
      margin-left: calc(50% - #{$vertical-form-fields-container-width}/2);
    }

    .tab-inner-content {
      position: absolute;
      left: $len-m;
      right: $len-m;
      top: $len-m;
      bottom: $len-m;
      overflow-y: auto;
    }
  }
}

@media (max-width: $bp-s) {
  .tabs {
    .tab-content {
      .tab-inner-content {
        left: 0;
        right: 0;

        .form.vertical {
          margin-left: 0;

          .form-controls-container {
            left: $len-m;
            width: calc(100% - 2 * #{$len-m});
          }
        }

        .tab-delete-container {
          z-index: 1;
        }

        .tab-actions-container {
          margin-bottom: $len-s;
          margin-left: $len-m;
          position: unset;

          & + .form.vertical {
            height: calc(100% - 96px);
          }
        }
      }
    }
  }
}
