@import '../styles/_constants.scss';

.range-date-picker {
  .input-dropdown-arrow {
    position: absolute;
    right: $len-s;
    top: calc(50% - 8px);
    pointer-events: none;
  }
}
