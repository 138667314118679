@import 'styles/_constants.scss';

.tabs-menu.onboarding {
  overflow: visible;
}

.tab-content.onboarding {
  .onboarding-tab-title {
    text-align: center;
    font-size: $font-size-l;
    font-weight: bold;
    height: 60px;
  }

  .form.vertical {
    height: calc(100% - 60px);
  }
}