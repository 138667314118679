@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-money-container {
    .input-money {
        @include textInput();
        width: calc(150px - #{$len-s}) !important;
        text-align: right;

        &.vertical {
            @include inputVertical();
        }

        &-currency {
            margin-left: $size-xs;
        }
    }

    &.horizontal {
        @include inputContainerHorizontal();
    }

    &.vertical {
        @include inputContainerVertical();
    }
}
