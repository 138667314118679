@import 'styles/_constants.scss';

.reservation-guest-details {
    margin-bottom: $size-m;

    .reservation-guest-line {
        margin-bottom: $size-s;

        span {
            font-weight: 700;
        }
    }
}


.input-custom.price-breakdown {
    width: 100%;

    .emphasized {
        font-weight: 700;
    }
}