@import '../styles/_constants.scss';

.key-value {

  .key-value-label {
    text-transform: uppercase;
    margin-bottom: $len-xs;
    color: $color-a-2;
    text-align: center;
  }

  .key-value-value {
    font-weight: 500;
    font-size: $font-size-xl;
    color: $color-a-4;
    text-align: center;
  }

  .progress {
    margin-top: $len-s;
  }
}