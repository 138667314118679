@import 'styles/_constants.scss';

.dashboard {
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;

  .dashboard-column {
    width: calc(50% - 2 * #{$len-s});
    vertical-align: top;
    overflow-y: auto;
    margin: $len-s;
    display: flex;
    flex-direction: column;
  }

  .widget {
    flex: 1 1 125px;
    min-height: 125px;

    &.empty {
      flex-grow: 0;
    }

    &.empty, &.first-column {
      flex: 0 1 auto;
    }

    &.current-guests {
      max-height: calc(100% - 160px);
    }

    .widget-content {
      .table-container {
        margin-top: -1px;
        border-radius: 0;

        .table-row:not(:hover) {
          .table-cell {
            border-bottom: 1px solid $color-d-2;
          }
        }

        .table-row.date-row {
           &:hover {
             border-top: none;
             .table-cell {
               border-top: none;
             }
           }
        }

        .table-cell {
          &:not(:last-child) {
            border-right: none;
          }
        }

        th {
          border-radius: 0 !important;
          z-index: 1;
          background-color: $color-d-2;
        }

        .date-cell {
          background-color: $color-w-1;
          border-bottom: 1px solid $color-d-5 !important;
          border-top: 1px solid transparent;
          font-size: $font-size-s;
          font-weight: 700;
          color: $color-d-5;
        }
      }
    }

    &.daily-summary {
      min-height: 140px;
      flex-grow: 0;
    }
  }

  .daily-summary-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: $len-sm 0;
    border: 1px solid $color-d-5;
    border-top: 0;
    box-sizing: border-box;

    .daily-summary-element {
      padding: $len-3xs 0;
      flex-grow: 1;

      &:not(:last-child) {
        border-right: 1px solid $color-d-2;

        .key-value {
          width: 60%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: $bp-l) {
  .dashboard {
    display: block;

    .dashboard-column {
      width: calc(100% - 2 * #{$len-s});
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

@media (max-width: $bp-m) {
  .dashboard {
    .widget {
      min-height: unset;

      .widget-content {
        .table-container {
          .table-row:not(:hover) {
            .table-cell {
              border-bottom: none;

              .checkbox-container.in-table {
                display: inline-block;
                vertical-align: text-bottom;
              }
            }
          }

          .table-row {
            border-left: 1px solid $color-d-5;
            border-right: 1px solid $color-d-5;

            &.date-row {
              border-bottom: none;
            }

            .table-cell {
              border-bottom: none;

              .checkbox-container.in-table {
                display: inline-block;
                vertical-align: text-bottom;
              }
            }

            .date-cell {
              margin-top: -($len-2xs);
              margin-bottom: -($len-2xs);
              grid-column: span 2;
              border-top: 1px solid $color-d-2;
              background-color: $color-d-7;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $bp-s) {
  .dashboard {
    .dashboard-column {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

}
