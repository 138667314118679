@import 'styles/_constants.scss';

.edit-booking-page {
  .table-container {
    max-height: calc(100% - 140px);
  }

  .action-button-container {
    margin-top: $len-2xs;
  }
}