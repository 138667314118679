@import '../../../client/src/styles/constants';

.vertical-navigation {
  position: relative;
  background-color: $color-a-2;
  min-width: fit-content;
  z-index: 20;

  .vertical-navigation-content {
    position: sticky;
    top: 0;
    width: fit-content;

    .logo {
      height: $len-2xl;
      display: flex;
      align-items: center;
      padding-left: $len-s;
    }

    .collapse-control {
      position: absolute;
      right: -$len-xs;
      top: $len-s;
      width: $len-m;
      height: $len-m;
      background-color: $color-w-1;
      border-radius: 50%;
    }

    nav {
      font-size: $font-size-s;
      border-top: 1px solid $color-a-3;

      .nav-item {
        display: block;
        height: $len-xl;
        line-height: $len-xl;
        padding: 0 $len-s;
        cursor: pointer;
        color: $color-w-1;
        border: 0;
        border-bottom: 1px solid $color-a-3;
        outline: 0;
        position: relative;
        background-color: transparent;
        text-align: unset;
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;

        &:hover [role=tooltip], &:focus [role=tooltip] {
          display: block;
        }

        [role=tooltip] {
          display: none;
          position: absolute;
          top: calc(50% - 12px);
          left: $len-2xl + $len-xs;
          background-color: $color-a-2;
          padding: $len-3xs $len-s;
          color: $color-w-1;
          border-radius: 3px;
          line-height: initial;

          &:before {
            border: 7px solid transparent;
            border-right-color: $color-a-2;
            content: "";
            height: 0;
            width: 0;
            pointer-events: none;
            position: absolute;
            top: calc(50% - 7px);
            left: -14px;
          }
        }

        .nav-label {
          margin-left: $len-s;
          margin-right: $len-s;

          &.hidden {
            display: none;
          }
        }

        &.sub-nav-item {
          padding-left: 52px;
        }

        &.active {
          background-color: $color-a-3;
        }

        &:hover, &:focus {
          background-color: $color-a-1;
        }
      }
    }
  }

  .main-menu {
    position: absolute;
    top: $len-sm;
    left: $len-s;
    z-index: 10;
    cursor: pointer;

    img {
      z-index: 1;
    }

    .navigation {
      background-color: $color-a-2;
      margin-top: -($len-ml);
      margin-left: -($len-2xs);
      z-index: 2;
      position: absolute;
    }
  }
}
