@import '../styles/_constants.scss';
@import '../styles/_mixins.scss';

.input-textarea-container {
  &.vertical {
    .input-label {
      vertical-align: top;
    }
  }

  .input-textarea {
    @include textInput();
    height: initial;
    resize: vertical;
    line-height: initial;
    padding-top: $len-xs;
    padding-bottom: $len-xs;

    &.vertical {
      @include inputVertical();
    }

    &.stacked {
      @include inputStacked();
    }
  }

  &.horizontal {
    @include inputContainerHorizontal();
  }

  &.vertical {
    @include inputContainerVertical();
  }

  &.stacked {
    @include inputContainerStacked();
  }

  .tooltip-container {
    vertical-align: top;
  }
}
